import React from 'react';
import Layout from '../../components/Layout';
import blogd1 from '../../assets/images/2022/sickle-cell/1.jpeg';
import blogd2 from '../../assets/images/2022/sickle-cell/2.jpeg';
import blogd3 from '../../assets/images/2022/sickle-cell/3.jpeg';
import blogd4 from '../../assets/images/2022/sickle-cell/4.jpeg';
import blogd5 from '../../assets/images/2022/sickle-cell/5.jpeg';
// import blogd6 from '../../assets/images/2022/sickle-cell/01.jpeg';
// import blogd7 from '../../assets/images/2022/federal-seminar/7.jpg';
// import blogd8 from '../../assets/images/2022/federal-seminar/8.jpg';
import NavOne from '../../components/NavOne';
import PageHeader from '../../components/PageHeader';
import Footer from '../../components/Footer';
 
// // Function will execute on click of button
//  const onButtonClick = () => {
//   // using Java Script method to get PDF file
//   fetch('SamplePDF.pdf').then(response => {
//       response.blob().then(blob => {
//           // Creating new object of PDF file
//           const fileURL = window.URL.createObjectURL(blob);
//           // Setting various property values
//           let alink = document.createElement('a');
//           alink.href = fileURL;
//           alink.download = 'SamplePDF.pdf';
//           alink.click();
//       })
//   })
// }
const NewsDetails = () => {
  return (
    <section className='blog-details'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='blog-one__single'>
              <div className='blog-one__image'>
                <img src={blogd5} alt='' />
              </div>
              <div className='blog-one__content text-center'>
                <h2 className='blog-one__title'>Post-Transplant Clinic</h2>
                <h5>Sickle Cell Foundation of Nigeria</h5>
                <h5>Lagos University Teaching Hospital</h5>
                <br />
                <h4>Introduction</h4>
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  Allogeneic Stem Cell Transplant (SCT), sometimes simply
                  referred to as Bone Marrow Transplant (BMT), is the transfer
                  of blood-forming cells from a healthy person (Donor), to
                  replace the bone marrow of an individual with various diseases
                  (Recipient). Eventually, the bone marrow of the recipient
                  begins to produce normal blood cells like the donor. Over the
                  past several years, many Nigerians seeking cure for various
                  blood disorders have often travelled abroad (mostly to India,
                  United Kingdom, and United States) for SCT/BMT. Standard care
                  for recipients of SCT/BMT is close follow-up in a
                  comprehensive Post-Transplant Clinic to improve long-term
                  survival. Individuals who have accessed the treatment abroad
                  often have to return to Nigeria where the required
                  comprehensive follow-up care is lacking.
                </p>
                {/* <div className='col-lg-6'>
                <img src={blogd6} alt='' />
              </div> */}
                <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd2} height={370} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd3} height={370} />
                  </div>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  THE SCFN­ LUTH POST STEM CELL TRANSPLANT CLINIC
                </span>
                <h5>THE SCFN­ LUTH POST STEM CELL TRANSPLANT CLINIC</h5>
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  To address this gap in care for the increasing population of
                  Nigerians who have undergone the transplant procedure abroad,
                  the Sickle Cell Foundation Nigeria (SCFN), in collaboration
                  with the Lagos University Teaching Hospital (LUTH), opened a
                  dedicated, multidisciplinary, Post - Transplant Clinic at the
                  newly established SCFN-LUTH Bone Marrow Transplant Centre,
                  LUTH Lagos - on the 3rd Floor of the Olikoye Ransome-Kuti
                  Children Emergency Centre, LUTH. The clinic is open to all
                  patients who have had stem cell/bone marrow transplant for
                  various forms of blood disorders.
                  <br />
                  The Post-Transplant Clinic is also important for drug
                  monitoring, maintaining the stem cell or bone marrow graft
                  (new donor cells), screening for disease recurrence and
                  providing required routine health maintenance care
                </p>
                <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd4} height={370} />
                  </div>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  An attendee making a comment at the seminar
                </span>

                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  At each visit to the clinic, a complete health assessment will
                  be carried out. This will include a full history and physical
                  examination, blood tests and sometimes, radiologic
                  examinations. The patient's drugs will be reviewed, and
                  adjustments made where necessary
                  <br />
                  The patient may also be referred to see doctors from various
                  specialties as indicated and follow-up appointments may be
                  scheduled, either face-to-face or virtually. The clinic is
                  strictly by appointment and interested persons are advised to
                  schedule appointments in time. (See email and phone number
                  below.
                </p>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  NOTE: The Post BMT Clinic is open to all patients who have had
                  Stem Cell transplant - irrespective of indication or reason
                  for the transplant.
                </span>
                <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd5} height={370} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd1} height={370} />
                  </div>
                </div>
                {/* <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Sections of the Seminar
                </span>
                <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd7} height={370} />
                  </div>
                </div> */}
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  More pictures
                </span>
              </div>
            </div>
            {/* <button onClick={onButtonClick}>
                    Download PDF
                </button> */}
            <div className='share-block'>
              <div className='left-block'>
                <p>
                  Tags: <a href='#none'>Community Health Department</a>
                </p>
              </div>
              {/* <div className='social-block'>
              <a href='#none'>
                <i className='fab fa-twitter'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-facebook-f'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-instagram'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-dribbble'></i>
              </a>
            </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const GalleryPage = () => {
  return (
    <Layout pageTitle='Medical Outreach to Mashewele Community  | LUTH News'>
      <NavOne />
      <PageHeader title='' />
      <NewsDetails />
      <Footer />
    </Layout>
  );
};

export default GalleryPage;
